<aca-page-layout>
  <div class="aca-page-layout-header">
    <adf-breadcrumb [root]="title" [folderNode]="node" (navigate)="onBreadcrumbNavigate($event)"> </adf-breadcrumb>
    <aca-toolbar [items]="actions"></aca-toolbar>
  </div>

  <div class="aca-page-layout-content">
    <div class="aca-details-container">
      <div class="aca-details-title">
        <div class="aca-details-breadcrumb" role="heading" aria-level="2" *ngIf="node">
          <span class="aca-details-breadcrumb-library">
            <img class="aca-details-breadcrumb-icon" alt="{{ 'APP.INFO_DRAWER.ICON' | translate }}" src="{{ nodeIcon }}">
            {{ node.name }} </span>
        </div>
        <div class="aca-details-buttons">
          <aca-toolbar [items]="aspectActions" info-drawer-buttons></aca-toolbar>
          <button
                class="aca-close-details-button"
                mat-icon-button
                data-automation-id="close-library"
                title="{{ 'APP.INFO_DRAWER.REDUCE_PANEL' | translate }}"
                (click)="goBack()">
                <mat-icon>fullscreen_exit</mat-icon>
          </button>
        </div>
      </div>

      <mat-tab-group [selectedIndex]="activeTab" class="aca-details-tabs" animationDuration="0" mat-stretch-tabs="false" mat-align-tabs="start">
        <mat-tab label="{{ 'APP.INFO_DRAWER.TABS.PROPERTIES' | translate }}">
          <ng-template matTabContent>
            <app-metadata-tab *ngIf="node && !isLoading; else loading" [node]="node"></app-metadata-tab>
          </ng-template>
        </mat-tab>
        <mat-tab label="{{ 'APP.INFO_DRAWER.TABS.COMMENTS' | translate }}">
          <ng-template matTabContent>
            <app-comments-tab *ngIf="node && !isLoading; else loading" [node]="node"></app-comments-tab>
          </ng-template>
        </mat-tab>
        <mat-tab [disabled]="!canManagePermissions" label="{{ 'APP.INFO_DRAWER.TABS.PERMISSIONS' | translate }}">
          <ng-template matTabContent>
            <adf-permission-list *ngIf="node && !isLoading; else loading" [nodeId]="node.id"></adf-permission-list>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</aca-page-layout>

<ng-template #loading>
  <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
</ng-template>
