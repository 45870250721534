<div class="aca-open-in-app">
  <div class="aca-mobile-application-container">
    <span>{{ 'APP.DIALOGS.MOBILE_APP.OPEN_ALFRESCO_MOBILE_APP' | translate }}</span>
    <button mat-icon-button mat-dialog-close class="aca-cross-button" (click)="onCloseDialog()" >
      <mat-icon class="aca-cross-button-icon">close</mat-icon>
    </button>
  </div>

  <div class="aca-open-in-app-button-container">
    <button mat-button (click)="openInApp()"
            data-automation-id="open-in-app-button"
            class="aca-open-in-app-button-container" cdkFocusInitial>
      <span>{{ 'APP.DIALOGS.MOBILE_APP.MOBILE_APP_BUTTON_LABEL' | translate }}</span>
    </button>
  </div>

  <div class="aca-download-app-container" *ngIf="appStoreUrl">
    <button mat-button data-automation-id="download-app-button" class="aca-download-app-container-button" (click)="downloadIosApp()">
      <span>{{ 'APP.DIALOGS.MOBILE_APP.DOWNLOAD_APP_BUTTON_LABEL' | translate }}</span>
    </button>
  </div>
</div>
