<div class="aca-context-menu">
  <ng-container [ngSwitch]="actionRef.type">
    <ng-container *ngSwitchCase="'menu'">
      <button mat-menu-item [id]="actionRef.id" [matMenuTriggerFor]="childMenu">
        <adf-icon [value]="actionRef.icon" class="app-context-menu-item--icon"></adf-icon>
        <span [attr.data-automation-id]="actionRef.id + '-label'">{{ actionRef.title | translate }}</span>
      </button>

      <mat-menu #childMenu="matMenu">
        <ng-container *ngFor="let child of actionRef.children; trackBy: trackByActionId">
          <app-context-menu-item [actionRef]="child"></app-context-menu-item>
        </ng-container>
      </mat-menu>
    </ng-container>

    <ng-container *ngSwitchCase="'separator'">
      <mat-divider></mat-divider>
    </ng-container>

    <ng-container *ngSwitchCase="'custom'">
      <adf-dynamic-component [data]="actionRef.data" [id]="actionRef.component"></adf-dynamic-component>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <button mat-menu-item [id]="actionRef.id" (click)="runAction()">
        <adf-icon [value]="actionRef.icon" class="app-context-menu-item--icon"></adf-icon>
        <span [attr.data-automation-id]="actionRef.id + '-label'">{{ actionRef.title | translate }}</span>
      </button>
    </ng-container>
  </ng-container>
</div>
