<form class="aca-rule-action__form" [formGroup]="form">

  <mat-form-field class="aca-rule-action-full-width" appearance="fill" subscriptSizing="dynamic">
    <mat-select
      formControlName="actionDefinitionId"
      data-automation-id="rule-action-select"
      [placeholder]="'ACA_FOLDER_RULES.RULE_DETAILS.ACTION_SELECT_PLACEHOLDER' | translate">
      <mat-option
        *ngFor="let actionDefinition of actionDefinitions"
        [value]="actionDefinition.id">
        {{ actionDefinition.title }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <adf-card-view
    *ngIf="cardViewItems?.length > 0"
    data-automation-id="rule-action-card-view"
    [properties]="cardViewItems"
    class="aca-rule-action-full-width"
    [editable]="!readOnly">
  </adf-card-view>

</form>
