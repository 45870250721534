<ng-container *ngIf="nodeId">
  <adf-alfresco-viewer
    [ngClass]="{
      'aca-right_side--hide': !showRightSide
    }"
    [maxRetries]="settings.viewerMaxRetries"
    [nodeId]="nodeId"
    [versionId]="versionId"
    [allowNavigate]="navigateMultiple"
    [allowRightSidebar]="true"
    [allowPrint]="false"
    [showRightSidebar]="true"
    [allowDownload]="false"
    [allowFullScreen]="false"
    [overlayMode]="true"
    [hideInfoButton]="true"
    [closeButtonPosition]="settings.viewerCloseButtonPosition"
    (showViewerChange)="onViewerVisibilityChanged()"
    [canNavigateBefore]="!!previousNodeId"
    [canNavigateNext]="!!nextNodeId"
    (navigateBefore)="onNavigateBefore($event)"
    (navigateNext)="onNavigateNext($event)"
  >
    <adf-viewer-sidebar *ngIf="infoDrawerOpened$ | async">
      <aca-info-drawer [node]="selection.file"></aca-info-drawer>
    </adf-viewer-sidebar>

    <adf-viewer-open-with *ngIf="openWith.length">
      <ng-container *ngFor="let action of openWith; trackBy: trackByActionId">
        <app-toolbar-menu-item [actionRef]="action"></app-toolbar-menu-item>
      </ng-container>
    </adf-viewer-open-with>

    <adf-viewer-toolbar-actions>
      <aca-toolbar [items]="toolbarActions"></aca-toolbar>
    </adf-viewer-toolbar-actions>
  </adf-alfresco-viewer>
</ng-container>
