<mat-icon class="adf-datatable-selected" *ngIf="isSelected" svgIcon="selected"></mat-icon>
<ng-container *ngIf="!isSelected">
  <ng-container *ngIf="isIcon; else image">
    <mat-icon>{{thumbnailUrl}}</mat-icon>
  </ng-container>
  <ng-template #image>
    <img [src]="thumbnailUrl" [alt]="tooltip" [title]="tooltip" />
  </ng-template>
</ng-container>

